<template>
	<div class="container">
		<div class="header" @click="expanded = !expanded">
			<h4>{{ header }}</h4>
			<font-awesome-icon class="expand-icon" :icon="expanded ? 'fa-minus' : 'fa-plus'" size="1x" />
		</div>
		<slide-up-down v-model="expanded" :duration="400">
			<div class="content">
				<div v-parse-links v-html="content" />
			</div>
		</slide-up-down>
	</div>
</template>

<script setup>
defineProps({
	header: { type: String, default: '' },
	content: { type: String, default: '' },
});

const expanded = ref(false);
</script>

<style lang="scss" scoped>
.header,
.content {
	padding: 20px;
	width: 100%;
	border-bottom: 1px solid #f1f1f1;
}

.container {
	text-align: left;

	.header {
		cursor: pointer;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;

		::before {
			content: '';
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			bottom: -1px;
			width: 10px;
			background-color: var(--cta-color);
		}

		.expand-icon {
			color: var(--dark-background-color);
			font-size: 1.2em;
		}

		h4 {
			margin-bottom: 0;
			font-size: 1.1em;
			padding-left: 15px;
		}
	}

	.content {
		padding: 20px;
		border-top: none;
		background-color: var(--secondary-background-color);

		ul,
		ol {
			padding: 0 0 0 20px;
		}

		:deep(:first-child) {
			margin-top: 0;
		}

		:deep(:last-child) {
			margin-bottom: 0;
		}
	}
}

@media (max-width: 880px) {
	.container {
		.header {
			h4 {
				font-size: 1em;
			}
		}
	}
}
</style>
